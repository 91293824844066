import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import * as Get from '@npm-libs/ng-getx';
import { ActionsComponent, IAction } from '@rcg/actions';
import { GqlInput, RcgPipesModule } from '@rcg/core';
import { FilterSetting, FiltersResult, RcgFiltersComponent } from '@rcg/filters';
import { IntlModule } from '@rcg/intl';
import { ModuleSearchComponent } from '@rcg/standalone';
import { DataCounterService } from '../data-counter/services/data-counter.service';

@Get.NgAutoDispose
@Component({
  selector: 'rcg-data-sum',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RcgPipesModule,
    ActionsComponent,
    RcgFiltersComponent,
    IntlModule,
    ModuleSearchComponent,
  ],
  templateUrl: './data-sum.component.html',
  styleUrls: ['./data-sum.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataCounterService],
})
export class DataSumComponent implements OnInit {
  @Input() gqlInput!: GqlInput;

  @Input() dataCount?: number | undefined;

  @Input() icon?: string;

  @Input() actions?: IAction[] = [];

  @Input() filtersSettings?: FilterSetting = undefined;

  @Output() action = new EventEmitter<IAction>();

  @Output() filterChange = new EventEmitter<FiltersResult>();

  @Output() search = new EventEmitter<string>();

  isActionActive = false;

  constructor(public dataCounterService: DataCounterService) {}

  ngOnInit(): void {
    if (this.gqlInput?.query) {
      this.dataCounterService.subscribeToCountData(this.gqlInput);
    }
  }

  onFilterChange(filters: FiltersResult): void {
    this.filterChange.emit(filters);
  }

  onSearch(event: string) {
    this.search.emit(event);
  }
}
