<ng-container>
  <mat-card appearance="outlined" class="data-sum">
    <mat-icon>{{ icon ?? 'functions' }}</mat-icon>
    <div *ngIf="dataCounterService.dataCountR.value$ | async as count; else noCount">
      <div style="display: flex; justify-content: flex-end; align-items: center; gap: 8px">
        <span>{{ count?.value ?? 0 | secondsToTime }}</span>
        @if(search.observed){
        <rcg-module-search
          style="display: flex; align-items: center"
          [search_CAPTION]="'placeholder_search' | intl"
          (searchPhrase)="onSearch($event)"
          [count]="undefined"
        >
        </rcg-module-search>
        } @if(filtersSettings?.id){
        <rcg-filters [count]="dataCount" [filterSetting]="filtersSettings" (filter)="onFilterChange($event)"> </rcg-filters>
        } @if(actions && actions.length > 0){
        <rcg-actions
          [actions]="actions"
          [actionSize]="'22px'"
          [actionViewType]="'icon'"
          (actionClicked)="action.emit($event)"
        ></rcg-actions>
        }
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-template #noCount> 0:00 </ng-template>
