import { inject, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@rcg/auth';

@Pipe({
  name: 'isOwner',
  standalone: true,
})
export class IsOwnerPipe implements PipeTransform {
  private readonly auth = inject(AuthService);

  transform(ownerId: number | undefined, tenantId: number | undefined): boolean {
    if (!tenantId || ![2, 194, 479, 480, 270, 232, 481].includes(tenantId)) return false;
    const user = this.auth.user();
    return user?.id === ownerId;
  }
}
