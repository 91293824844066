import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Input } from '@angular/core';
import { RcgPipesModule, TenantId } from '@rcg/core';
import { RcgCardComponent } from '@rcg/shared';

interface ProjectCategory {
  id: number;
  description: string;
}

interface Customer {
  id: number;
  short_name: string;
}

interface Project {
  id?: number;
  form_id?: number;
  created_at?: string;
  updated_at?: string;
  short_description?: string;
  code?: string | null;
  is_active?: boolean;
  project_category?: ProjectCategory;
  customer?: Customer;
  planned_hours?: number;
  worked_hours?: number;
}
@Component({
  selector: 'rcg-project',
  standalone: true,
  imports: [CommonModule, RcgPipesModule, RcgCardComponent],
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectComponent {
  data = input<Project>();
  @Input() tenantId!: number;
  @Input() category: string | undefined;

  tenant = TenantId;

  workedPercentage = computed(() => {
    const planned = this.data()?.planned_hours;
    const worked = this.data()?.worked_hours;

    if (planned === undefined || worked === undefined || planned <= 0) return '';

    const percentage = (worked / planned) * 100;
    if (percentage < 1) return '';

    return `${Math.round(percentage)}%`;
  });
}
