@if(data(); as data){
<rcg-card [ngClass]="{ 'hours-exceeded': data.worked_hours && data.planned_hours && data.worked_hours > data.planned_hours }">
  <!-- Title row -->
  <div title>
    <ng-container *ngIf="data?.customer?.short_name">
      <span class="one-line-text" style="font-size: 14px; font-weight: 500; margin-right: 4px">
        {{ data.customer?.short_name }}
      </span>
    </ng-container>
  </div>

  <div titleAction>
    <span style="font-size: 12px">
      {{ data.created_at | date : 'short' }}
    </span>
  </div>

  <!-- Subtitle row -->
  <div subtitle class="max-two-lines" style="font-size: 12px">
    <div>
      {{ data.short_description ?? '' }}
    </div>
  </div>

  <div subtitleAction>
    <div style="font-size: 12px">
      {{ data.updated_at ?? '' | dateToNow }}
    </div>
  </div>

  <!-- Content row -->
  <div content>
    <div style="font-size: 12px" class="one-line-text">
      {{ data.project_category?.description ?? '' }}
    </div>
  </div>

  <div contentAction>
    <div style="font-size: 12px">
      {{ data.code ?? '' }}
    </div>
    <div style="text-align: right">{{ workedPercentage() }}</div>
  </div>
</rcg-card>
}
