<ng-container *ngIf="tenantId && data?.id">
  <!-- Project category-->
  <ng-container *ngIf="category === 'project'">
    <rcg-project
      (click)="itemClick($event)"
      (dblclick)="openDetailWindow(data?.id)"
      [ngStyle]="{
        display: 'flex',
        height: '100%',
        width: '100%',
        'background-color': 'var(--rcg-is-selected, var(--rcg-selected-color)) var(--rcg-is-unselected, var(--rcg-unselected-color))'
      }"
      [tenantId]="tenantId"
      [category]="category"
      [data]="data"
      [class.rcg-active-route]="active"
    >
    </rcg-project>
  </ng-container>

  <ng-container *ngIf="category !== 'project'">
    <div style="height: 100%" [ngSwitch]="tenantId">
      <ng-container *ngSwitchCase="tenant.Halcom" [ngTemplateOutlet]="halcom"> </ng-container>
      <ng-container *ngSwitchCase="tenant.InterexportLocalIt" [ngTemplateOutlet]="interExport"> </ng-container>
      <ng-container *ngSwitchCase="tenant.InterexportMedicina" [ngTemplateOutlet]="interExport"> </ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="default"> </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #default>
  <rcg-card
    [colorBand]="data?.color ? data.color?.trim()?.toLowerCase() : undefined"
    [colorBandTooltip]="
      data?.color === 'red' ? ('contact_reply' | intl) : data?.color === '#ffb300' ? ('third_person_reply' | intl) : ('agent_reply' | intl)
    "
    [class.rcg-active-route]="active"
    (click)="itemClick($event)"
    (dblclick)="openDetailWindow(data?.id)"
    [ngStyle]="{
      'background-color': data?.worked_service_hours && data?.planned_service_hours && data?.worked_service_hours > data?.planned_service_hours ? '#ff9999' : (data?.bg_color || ''),
    }"
    [class.rcg-right-border-color]="data?.owner?.id | isOwner : tenantId"
  >
    <span
      title
      class="one-line-text"
      style="font-size: 14px"
      [style]="tenantId === tenant.InterexportRadiologija ? { 'font-weight': 'normal' } : { 'font-weight': '500' }"
    >
      <ng-container *ngIf="data?.customer?.short_name">
        <span style="margin-right: 4px">
          {{ data.customer.short_name ?? '' }}
        </span>
      </ng-container>
      <ng-container *ngIf="data?.contact?.full_name">
        <span *ngIf="data?.customer?.short_name && data?.contact?.full_name" style="margin-right: 4px"> - </span>
        <span *ngIf="data?.customer?.short_name">
          {{ data.contact.full_name ?? '' }}
        </span>
        <span *ngIf="!data?.customer?.short_name">
          {{ data.contact.full_name ?? '' }}
        </span>
      </ng-container>
    </span>

    <span titleAction style="display: flex; align-items: center">
      <rcg-priority-indicator *ngIf="data?.priority?.icon" [priority]="data.priority"> </rcg-priority-indicator>

      <ng-container *ngIf="data?.status?.color">
        <rcg-status-color
          [color]="data.status.color"
          [matTooltip]="data?.status?.description + (data?.rating ? ' (' + data.rating + ')' : '')"
          matTooltipPosition="above"
        >
        </rcg-status-color>
      </ng-container>
    </span>

    <div subtitle class="one-line-text">
      <div
        [style]="tenantId === tenant.InterexportRadiologija ? { 'font-weight': '500', color: 'black' } : { color: '#555555' }"
        style="font-size: 13px"
      >
        {{ data?.short_description ?? '' }}
      </div>
    </div>

    <div subtitleAction style="display: flex">
      <mat-icon class="attachment" *ngIf="data?.has_attachments === true">attach_file</mat-icon>
      <rcg-avatar *ngIf="data?.owner?.full_name" color="PRIMARY" size="X-SMALL" [text]="data.owner.full_name"> </rcg-avatar>
    </div>

    <div content>
      {{ data?.created_at | date : 'short' }}
      @if([tenant.InterexportKm,tenant.InterexportKmHrv,tenant.InterexportKmSr,tenant.InterexportRadiologija,tenant.Rcg].includes(tenantId)){
      <div>
        {{ data?.id ?? '' }}
        <span data-parent-id="true" *ngIf="data?.parent_id">
          &nbsp;&nbsp;----->&nbsp;&nbsp;
          <span data-parent-id="true" style="color: blue; text-decoration: underline; cursor: pointer">{{ data?.parent_id }}</span>
        </span>
      </div>
      }
    </div>
    <div contentAction>
      {{ data?.communications_aggregate?.aggregate?.max?.work_from_dt ?? data?.updated_at | dateToNow }}
      {{ data?.worked_planned_percentage ? '(' + (data?.worked_planned_percentage | number : '1.2-2') + '%)' : '' }}
    </div>
  </rcg-card>
</ng-template>

<ng-template #interExport>
  <rcg-card
    [colorBand]="data?.color ? data.color?.trim()?.toLowerCase() : undefined"
    [class.rcg-active-route]="active"
    (click)="itemClick($event)"
    (dblclick)="openDetailWindow(data?.id)"
    [ngStyle]="{
      'background-color': data?.bg_color ? data.bg_color : '',
    }"
    [class.rcg-right-border-color]="data?.owner?.id | isOwner : tenantId"
  >
    <span title class="one-line-text">
      <ng-container *ngIf="data?.short_description">
        <span style="font-size: 14px; font-weight: 500">
          {{ data?.short_description ?? '' }}
        </span>
      </ng-container>
    </span>

    <div titleAction style="display: flex; align-items: center">
      <rcg-priority-indicator *ngIf="data?.priority?.icon" [priority]="data.priority"></rcg-priority-indicator>
      <ng-container *ngIf="data?.status?.color"
        ><rcg-status-color
          [color]="data.status.color"
          [matTooltip]="data?.status?.description + (data?.rating ? ' (' + data.rating + ')' : '')"
          matTooltipPosition="above"
        ></rcg-status-color>
      </ng-container>
    </div>

    <span subtitle class="one-line-text">
      <ng-container *ngIf="data?.customer?.short_name">
        <span style="font-size: 12px">
          {{ data.customer.short_name ?? '' }}
        </span>
      </ng-container>
      <span *ngIf="data?.customer?.short_name && data?.contact?.full_name"> - </span>
      <ng-container *ngIf="data?.contact?.full_name">
        <span style="font-size: 12px">
          {{ data.contact.full_name }}
        </span>
      </ng-container>
    </span>
    <div subtitleAction style="font-size: 12px">
      {{ data?.updated_at | dateToNow }}
    </div>

    <div content>
      <div style="font-size: 12px">
        {{ data?.created_at | date : 'short' }}
      </div>
      <div style="font-size: 12px">
        {{ data?.id ?? '' }}
      </div>
    </div>

    <div contentAction style="display: flex">
      <mat-icon class="attachment" *ngIf="data?.has_attachments === true">attach_file</mat-icon>
      <rcg-avatar *ngIf="data?.owner?.full_name" color="PRIMARY" size="X-SMALL" [text]="data.owner.full_name"> </rcg-avatar>
    </div>
  </rcg-card>
</ng-template>

<ng-template #halcom>
  <rcg-card
    [colorBand]="data?.color ? data.color?.trim()?.toLowerCase() : undefined"
    [class.rcg-active-route]="active"
    (click)="itemClick($event)"
    (dblclick)="openDetailWindow(data?.id)"
    [ngStyle]="data?.bg_color ? { 'background-color': data.bg_color } : {}"
  >
    <span title class="one-line-text">
      <ng-container *ngIf="data?.customer?.short_name">
        <span style="font-size: 14px; font-weight: 500; margin-right: 4px; white-space: nowrap">
          {{ data.customer.short_name ?? '' }}
        </span>
      </ng-container>
      <ng-container *ngIf="data?.contact?.full_name">
        <span *ngIf="data?.customer?.short_name && data?.contact?.full_name" style="margin-right: 4px"> - </span>
        <span *ngIf="data?.customer?.short_name" style="font-size: 14px; font-weight: 500; white-space: nowrap">
          {{ data.contact.full_name }}
        </span>
        <span *ngIf="!data?.customer?.short_name" style="font-size: 14px; font-weight: 500; white-space: nowrap">
          {{ data.contact.full_name }}
        </span>
      </ng-container>
    </span>
    <ng-container titleAction *ngIf="data?.status?.color">
      <rcg-status-color
        [color]="data.status.color"
        [matTooltip]="data?.status?.description + (data?.rating ? ' (' + data.rating + ')' : '')"
        matTooltipPosition="above"
      >
      </rcg-status-color>
    </ng-container>

    <span subtitle class="one-line-text">
      {{ data?.external_number ? data.external_number : data.id }}
    </span>
    <div subtitleAction>
      {{ data?.created_at | date : 'short' }}
    </div>

    <div content class="one-line-text">
      {{ data?.short_description ?? '' }}
    </div>
    <rcg-avatar contentAction *ngIf="data?.owner?.full_name" color="PRIMARY" size="X-SMALL" [text]="data.owner.full_name"> </rcg-avatar>
    <div actions *ngIf="data.data?.averageTimeSpent" class="row-end">
      <div>
        {{ data.data?.averageTimeSpent | secondsToTimeShowSeconds }}
      </div>
    </div>
  </rcg-card>
</ng-template>
